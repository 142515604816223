@import "../vendor/bootstrap/less/bootstrap";
@import "../vendor/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";
/*
* Vendor specific
*/
@import "vendors/parsley";
@import "vendors/slick";

html, body {
  height: 100%;
  background: @brand-white;
  color: @gray-base;
}

h1 {
  letter-spacing: 5px;
}

b, strong {
  font-weight: 700;
}

#content {
  padding-top:25px;
}

#container {
  min-height: 100%;
}

#sidebar {
  padding-top:0px;
}

// .container {
//   width:1200px;
// }



// icon video
.image-responsive-4by3 {
  position: relative;
  padding-bottom: 75%;
  display: block;
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.icon.play:before { 
  content: "\f144";
  font-family: FontAwesome;
  position: absolute;
  top: ~"calc(50% - 24px)";
  z-index: 100;
}
.icon-3x.center:before {
  font-size: 3em;
  line-height: 1;
  left: ~"calc(50% - 21px)";
}

.thumbnail-caption {
  padding: 4px 0 0 0;
  font-size: (@font-size-base * 0.75%);
}

.article-image {
  margin-bottom: @line-height-computed;
}

.article-image.align-left {
  padding-left: 0;
  padding-right: 15px;
}

.article-image.align-right {
  padding-right: 0;
  padding-left: 15px;
}

.article-image.align-center {
  text-align: center;
  .caption {
    text-align: left;
  }
  .thumbnail {
    display: inline-block;
  }
}

.article-image.align-full {

}

.article-image.align-left, .article-image.align-right {

  @media (min-width: @screen-xs) {
    max-width: 50%;
  }

  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
  }

}

.mt-1 {
  margin-top: @line-height-computed * 1px;
}

.mt-2 {
  margin-top: @line-height-computed * 2px;
}

.mt-3 {
  margin-top: @line-height-computed * 3px;
}

.mb-1 {
  margin-bottom: @line-height-computed * 1;
}

.mb-2 {
  margin-bottom: @line-height-computed * 2;
}

.mb-3 {
  margin-bottom: @line-height-computed * 3;
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

.align-right, .align-left {
  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}

.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.text-align-center {
  text-align: center;
}

.vertical-align-middle {
  position: relative;
  top: 50%;
  .translate(0%, -50%);
}

.horizontal-align-center {
  .translate(-50%, -50%);
  left: 50%;
  position: absolute;
  z-index: 99;
}

.caption {
  font-size: @font-size-small;
  min-height: @font-size-small;
  box-sizing: content-box;

  p {
    margin-bottom: 0;
  }

  .article-image-attribution {
    float: right;
  }
}

blockquote {
  font-size: @font-size-base;
  padding: @panel-body-padding;
  margin-bottom: @panel-body-padding;
  font-style: italic;
  color: @brand-dark-blue;
}


.page-header {
  border-bottom: 2px solid @brand-dark-blue;
  margin-top: 0;
  .page-title {
    margin-bottom: 0;
  }

  .page-title-sub {
    margin-bottom: 0;
  }
}

.page-title {
  margin-top: @font-size-h1;
  margin-bottom: @font-size-h1;
  letter-spacing: 1px;
}

.article {
  padding-bottom: @padding-large-vertical;

  .article-title {
    &:extend(.page-title);
  }

  .article-byline {
    position: relative;
    text-align: center;
    margin-bottom: @font-size-h1;
  }

  .page-title {
    margin-bottom:10px;
    margin-top:0px;
  }

  h1 {
    color:@brand-dark-blue;
  }

  h2 {
    color:@brand-green;
    margin-top:0px;
    margin-bottom: 15px;
  }
}


.sidebar {
  h2:first-child {
    margin-top:0px;
    border-bottom:1px solid @gray-light;
    color:@brand-dark-blue;
  }
  ul {
    padding-left: 1.5em;
    li {
      a {
        color:@gray-base;
        text-decoration:none;
        &:hover {
          color:@brand-primary;
        }
      }
    }
  }


}

.sidebar-menu {
  margin-bottom: (@grid-gutter-width/2);
}

#sidebar {
  margin-bottom: (@grid-gutter-width/2);
  .pull-quote {
    border-top: 1px solid #E1E1E1;
  }
}

.sidebar-title {
  margin-top: 0;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: @padding-small-vertical;
}

.sidebar-title-sub {
  margin-top: 10px;
  color: @brand-green;
}

.list-links {
  padding-left: 1.5em;
}


.list-accordion {
  list-style: none;

  .panel {
    
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
  }

  .panel.active > a:not(.collapsed) + .collapse {
    display: block;
  }



    a {
       color: @link-color;
       display: block;
    }


  [data-toggle="collapse"] {
    padding-right: 15px;
    position: relative;

    
    &:before {
      .fa-icon();
      position: absolute;
      top: 7px;
      right: 0;
      font-size: 0.75em;
      color: @brand-blue;
    }

    &.collapsed:before {
      content: @fa-var-chevron-circle-down;
    }

    &:not(.collapsed):before {
      content: @fa-var-chevron-circle-up;
    }
  }

  li.panel > a:hover,
  li.active.panel > a {
      color: @brand-blue;
  }

  li:not(.panel) > a:hover,
  li.active:not(.panel) > a {
       color: @brand-green; 
  }

  > li {
    position: relative;


    &:before {
        content: '\2022';
        position:absolute;
        right:100%;
        margin-right: 0.5em;
      }
  }
}

.article-body {
  .clearfix();
}

.media {
  border: 1px solid @gray-light;
  padding: @panel-body-padding;
  margin-bottom: @font-size-base;
  border-radius: @border-radius-small;

  .media-right,
  .media > .pull-right {
    padding-left: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-left,
  .media > .pull-left {
    padding-right: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-date-byline {
    border-top: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: @padding-base-vertical;
  }

  .media-summary {
    p:last-child {
      margin-bottom: @padding-base-vertical;
    }
  }

  &.media-responsive {
    .media-right, .media-left {

      @media (max-width: @screen-xs) {
        display: block;
        float: none !important;
        padding-right: 0;
        padding-left: 0;

        .media-object {
          width: 100%;
        }
      }

      @media (min-width: @screen-xs + 1) {
        .media-object {
          width: 200px;
        }
      }

      @media (min-width: @screen-md) {
        .media-object {
          width: 160px;
        }
      }

    }
  }
}

// drop down

.nav-stacked {
  .nav-stacked {
    > li > a {
      padding-left: 40px;
    }
  }
}

.edit-this-page {
  font-size: 14px !important;
  background: #fff;
  display: inline-block;
  padding: 4px;
  height: 22px;
  width: 22px;
  line-height: 14px;
  color: @gray;

  &:hover {
    background: @gray;
    color: #fff;
  }
}

//homepage banner slider
.carousel, .slick-track, .carousel-inner, .slick-list, .bg-slide {
  height: 100%;
  position: relative;
}

.slick-slide img {
  width: 100vw;
  height: 100vh;
}

.bg-slide {
  background-size: cover;
  background-repeat: no-repeat;
}

.caption {
  position: absolute;
  right: 0;
  background: #252525;
  padding: 10px;
  top:53%;
  color: @color-white;
  z-index: 1;
  text-align: right;
  
  .annual-report-container {
    .main-title {
      border-bottom: 3px solid @color-light-blue;
      text-transform: uppercase;

        h1{
          font-size: 20px;
            @media(min-width: @screen-sm-min){
              font-size:30px;
            }
        }
    }

    .sub-title h2{
      font-size: 18px;
      @media(min-width: @screen-sm-min){
        font-size:24px;
      }
    }
  }
  @media(min-width: 375px){
    top: 60%;
  }

  @media(min-width: 376px){
    top: 16%;
    max-width: 300px;
  }
  @media(min-width: @screen-xs-max){
    right: 5%;
    max-width: 400px;
    padding: 15px 30px;
    top: 0;
  }
}

.related-links {
  .list-group {
    .list-group-item {
      background-color: transparent;
      padding-left: 10px;
    }
  }
}

.navbar.navbar-default {
  border-width: 0;
  border-radius: 0;
}
#primaryNav .navbar-nav {
  background-color: @brand-blue;
  margin-bottom: 0;
    @media (min-width: @grid-float-breakpoint){

    li:last-child {

      &:after {
         content: '';
         height: 100%;
         background-color: @brand-blue;
         width: 1000px;
         position: absolute;
         z-index: 0;
         top: 0;
         left: 100%;
        }
    }
  }
}
// footer styles
#footer {
  background: @brand-dark-green;
  margin-bottom: 0px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  z-index:2;
  color: @brand-white;

  .navbar-collapse {
    box-shadow: none;
    border-top: none;
  }



  .navbar-toggle {
    margin-top: 18px;
  }
  .background-split,
  .list-locale {
   margin-bottom: 5px;
 }

 .background-split {
  border-bottom: 1px solid darken(@brand-green, 3%);
  overflow: hidden;
  background-color: @brand-green;
}
  

  .light-green {
    background:@brand-green;
    line-height:52px;
    text-transform:uppercase;
    font-size:@font-size-h2;
    padding-right:0px;

    @media(min-width: @screen-sm) {
      width: 215px;
    }
  }

  .light-blue {
    float: right;
    padding: 0px 15px;
    background: @brand-primary;
    margin-left: 15px;
  }

  .list-locale {
    margin-left: -2px;
    

    .list-locale-link {
      display: block;
      padding: @padding-small-vertical 0;
      border-radius: 4px;
      line-height: 1;
      color: #fff;
      font-size: @font-size-small;
    }

    > li {
      padding: 0 2px;
      display: inline-block;
    }

    a.list-locale-link {
      &.active, &:hover, &:focus {
        background-color: lighten(@brand-dark-green, 7.5%);
        border-color: darken(@brand-dark-green, 4.5%);
      }
      border: 1px solid darken(@brand-dark-green, 1.5%);
      background-color: lighten(@brand-dark-green, 2.5%);
      padding: @padding-small-vertical @padding-small-horizontal;
    }
  }
  
  // .top-header {
  //   height: 50px;
  //   padding: 15px 0;

  // .top-header-link {
  //   display: inline-block;
  //   padding: 2px @padding-small-vertical;
  //   line-height: 1;
  //   position: relative;
  //   color: @brand-dark-blue;

  
  //   &:not(:first-child):after {
  //     content: '';
  //     position: absolute;
  //     left: 0;
  //     top: 3px;
  //     height: ~"calc(100% - 6px)";
  //     width:0;
  //     border-left: 1px solid;
  //   }
  //   span {

  //   }

  //   &.active span,
  //   &.active:hover span {
  //     background-color: @brand-info;
  //     color: white;
  //   }

  //   &:hover {
  //     text-decoration: none;
  //     span {
  //       background-color: lighten(@brand-info, 10%);
  //       color: white;
  //     }
  //   }
  // }
  // }  

  .navbar-brand {
    height:auto;
    @media(min-width: @grid-float-breakpoint) {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: @grid-gutter-width;
    }

    > img {
      height: 44px;
    }
  }

  .medium-blue {
    background: @brand-blue;
    @media(min-width: @screen-md){
      height: 67px;
    }
  }
  
}

.dropup {
  .dropdown-menu {
    margin-bottom: 0;
  }
}

.dropdown-menu {
  background-color: @brand-dark-blue;
  border-radius: 0;
  > li  {
    > a {
      color: @color-white;
      &:hover {
        background-color: @brand-blue;
        color: @color-white;
      }
    }
  } 
}

.navbar-nav > li > a {
  @media(min-width: @grid-float-breakpoint) {
    padding-top: 20px;
    padding-bottom: 20px;
  } 
}

// Nav bar dropdown
.navbar-nav > li.dropdown {
  > a {
    padding-right: 30px;
    &:before {
      .fa-icon();
      content: @fa-var-chevron-circle-up;
      position: absolute;
      top: ~"calc(50% - 6px)";
      right: 9px;
      font-size: 0.75em;
    }
  }
}

// Nav form

.navbar-form {
  .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid @gray-light;
    border-radius: 0;
    color: @gray-lighter;
    box-shadow: none;
  }
}

// share

.article-sharing {
  min-height: 28px;
  text-align: center;
  margin-bottom: @font-size-h1;

}

.share-btn {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  color: @text-color;
  font-size: 14px;
}

/* notifications */
#notifications {
  z-index: 101;
}

body.ltr #notifications {
  left: 0;
}

body.rtl #notifications {
  right: 0;
}

#notifications.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
}

#notifications {
  text-align: center;

  .notification {
    .alert;
  }
  .notification[class*="message"] {
  }
  .notification[class*="notice"] {
    .alert-info;
  }
  .notification[class*="error"] {
    .alert-danger;
  }

  .notification {
    margin-bottom: 0;
    border-radius: 0;

    a {
      color: inherit;
      // &:hover {
      //   text-decoration: none;
      // }
    }
  }

  .notification + .notification {
    border-top: 0;
  }

}

// Dropdown sub-menu
.nav {
  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
  }

  .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }

  .dropdown-submenu > a:after {
    .fa();
    .fa-icon();
    content: @fa-var-chevron-right;
    position: relative;
    top: ~"calc(50% - 6px)";
    right: -9px;
    font-size: 0.75em;
  }

  .dropdown-submenu:hover > a:after {
    border-left-color: #fff;
  }

  .dropdown-submenu.pull-left {
    float: none;
  }

  .dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
  }
}


h1.gtmwarning {
  text-align: center;
  background: red;
}

/* Gallery */

.grid-sizer, .grid-item {
  width: 33%;
  margin-bottom: 5px;

}

.grid-item-width2 {
  width: percentage(8/12);
}

.grid-item-height2 {
  img {
    width: 100%;
    overflow: hidden;
  }
  .lightbox {
    padding-bottom: 100%;
  }
}

.gallery-link {
  position: relative;

  a:hover .caption {
    display: block;
  }

  .caption {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

}

.mfp-description {
  color: #ccc;
  margin-top: 10px;
}

a.thumbnail:hover, a.thumbnail:active {
  text-decoration: none;
}

.fullscreen {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100vw;
  z-index:1;
}

.site-title {
  z-index: 2;
  position: relative;
  text-align: right;
  margin-top: 10%;
    //border-bottom: 3px solid @brand-primary;
    font-size: @font-size-h1;
    color: @brand-white;
    float: right;
    width:40%;
  }

  .navbar-default {
    .navbar-nav {
      li {
        &:hover, &.active {
          background:@brand-dark-blue;
        }
        a:before {
          .fa();
        //content:@fa-var-chevron-circle-up;
      }
    }
  }
}

.hr {
  background:@brand-dark-blue;
  height:2px;
  width:100%;
  margin-bottom:15px;
}













