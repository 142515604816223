[data-slick-carousel-default] {

  &.slick-slider {
    margin-bottom: 0;
  }
  .slick-dots {
    bottom: 10px;
  }
  .slick-prev {
    left: 10px;
    z-index: 1;
  }
  .slick-next {
    right: 10px;
  }

  .slick-slide {
    img {
      margin: 0 auto;
    }
  }
}
